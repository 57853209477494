

import Cookies from 'js-cookie'

const getCookieItem = key => {
    return Cookies.get(key)
}

const setCookieItem = (key, value, ttl) => {
    const ttlInMinutes = 1 / 24 / 60 * ttl
    Cookies.set(key, value, { expires: ttlInMinutes })
}

const removeCookieItem = (key) => {
    Cookies.remove(key)
}

const COOKIE_ITEMS = {
    counter: 'counter',
    redirect: 'redirect',
    popupdiabled: 'popupdisabled',
    newfeaturepopup: 'newfeaturepopup',
    endpoint: 'endpoint'
}
  
export { getCookieItem, setCookieItem, removeCookieItem, COOKIE_ITEMS };
  