/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Button, Row, Col, Switch, Avatar } from 'antd';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import TopMenu from './TopMenu';
import { Div, SmallScreenAuthInfo } from './style';
import AuthMenu from '../components/utilities/auth-info/AuthMenu';
import { changeRtlMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import Hamburger from 'hamburger-react'
import LoginPopup from './LoginPopup';
import DevEndpointModal from '../container/profile/settings/DevEndpointModal';
const { darkTheme } = require('../config/theme/themeVariables');

const { Header, Footer, Sider, Content } = Layout;
// const { darkMode } = config;

const ThemeLayout = WrappedComponent => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        customizerAction: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide } = this.state;
      const { ChangeLayoutMode, rtl, topMenu, changeRtl, changeLayout, changeMenuMode } = this.props;

      const darkMode = ChangeLayoutMode;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };


      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };

      const footerStyle = {
        padding: '20px 30px 18px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: 'rgba(255, 255, 255, .90)',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      const onRtlChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'rtl');
        changeRtl(true);
      };

      const onLtrChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'ltr');
        changeRtl(false);
      };

      const modeChangeDark = () => {
        changeLayout(true);
      };

      const modeChangeLight = () => {
        changeLayout(false);
      };

      const modeChangeTopNav = () => {
        changeMenuMode(true);
      };

      const modeChangeSideNav = () => {
        changeMenuMode(false);
      };

      const onEventChange = {
        onRtlChange,
        onLtrChange,
        modeChangeDark,
        modeChangeLight,
        modeChangeTopNav,
        modeChangeSideNav,
      };

      
      document.body.style.zoom = "90%"
      
      return (
        <Div style={{ height: "100%", WebkitTextSizeAdjust: "90%"}} darkMode={darkMode}>
          <Layout style={{minHeight: "100%"}} className="layout">
            
            <Row justify="center">
            <Col xxl={20} lg={20} md={24} sm={24} xs={24}>
              <Layout className={window.innerWidth > 1350 ? "atbd-main-layout" : "atbd-main-layout mobile"}>
                <Content>
                  <WrappedComponent {...this.props} />
                </Content>
              </Layout>
            </Col>
            </Row>
            <Row justify="center" style={{marginTop: "auto"}}>
            <Col xxl={20} lg={20} md={24} sm={24} xs={24}>
              <Footer className="admin-footer" style={footerStyle}>
                <Row>
                  <Col md={12} xs={24}>
                  <span className="admin-footer__copyright">Copyright © {new Date().getFullYear()}. Codenary All Rights Reserved.</span>
                  </Col>
                  <Col md={12} xs={24}>
                    <div className="admin-footer__links">
                      <DevEndpointModal/>
                      <a style={{marginRight: "20px", textDecorationLine: "underline", verticalAlign: "top"}} 
                        onClick={() => window.open(`${window.location.origin}/agreement.html`, '_blank')}>
                        서비스 이용약관
                      </a>
                      <a style={{marginRight: "20px", textDecorationLine: "underline", verticalAlign: "top"}} 
                        onClick={() => window.open(`${window.location.origin}/privacy.html`, '_blank')}>
                        개인정보 처리방침
                      </a>
                      <a onClick={() => window.open(`https://www.instagram.com/codenary_official`, '_blank')}>
                        <FeatherIcon icon="instagram" size={22} />
                      </a>
                      <a href="mailto:contact@codenary.co.kr">
                        <FeatherIcon icon="mail" size={22} />
                      </a>

                      {/* <SemiButton
                        icon={
                          <div style={{display: "inline-block", verticalAlign: "middle"}}>
                            <Lottie animationData={bulb_animation} loop={false} autoplay={true} style={{height: "32px", display: "inline-block"}}/>
                          </div>
                        }
                        type='tertiary'
                        theme="borderless"
                        style={{ borderRadius: "0.5rem", color: "#888888", verticalAlign: "middle" }}
                      >
                        서비스 의견
                      </SemiButton> */}
                    </div>
                  </Col>
                </Row>
              </Footer>
            </Col>
            </Row>
          </Layout>
        </Div>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  const mapStateToDispatch = dispatch => {
    return {
      changeRtl: rtl => dispatch(changeRtlMode(rtl)),
      changeLayout: show => dispatch(changeLayoutMode(show)),
      changeMenuMode: show => dispatch(changeMenuMode(show)),
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;
