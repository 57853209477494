import axios from 'axios';
import { COOKIE_ITEMS, getCookieItem } from './cookieControl';
import { getLocalStorageItem, LOCALSTORAGE_ITEMS } from './localStorageControl';

export const BASEURL = process.env.REACT_APP_BASE_URL

export const ENDPOINT = process.env.REACT_APP_STAGE === "production"
                        ? process.env.REACT_APP_API_ENDPOINT
                        : getCookieItem(COOKIE_ITEMS.endpoint) || process.env.REACT_APP_API_ENDPOINT

export const STATIC_ENDPOINT = process.env.REACT_APP_STATIC_ENDPOINT
export const COMPANY_LOGO_ENDPOINT = `${STATIC_ENDPOINT}/company-logo`
export const FRAMEWORK_LOGO_ENDPOINT = `${STATIC_ENDPOINT}/framework_logo`

export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT
export const SHARE_ENDPOINT = process.env.REACT_APP_SHARE_ENDPOINT


export const authHeader = (token) => (
  {
    headers: {
      Authorization: `Bearer ${token || getLocalStorageItem(LOCALSTORAGE_ITEMS.userinfo)?.access_token}`,
      'Content-Type': 'application/json'
    }
  }
);

export const API_ENDPOINT = {
  company: {
    getBasicInfo: (keyword, category) => {
      if(keyword && category) return axios.get(`${ENDPOINT}/company`)
      else if (keyword) return axios.get(`${ENDPOINT}/company?keyword=${keyword}`)
      else if (category) return axios.get(`${ENDPOINT}/company?category=${category}`)
      else return axios.get(`${ENDPOINT}/company`)
    },
    getCompanyList: (page, keyword, category) => {
      if(keyword && category) return axios.get(`${ENDPOINT}/company/list?page=${page}`)
      else if (keyword) return axios.get(`${ENDPOINT}/company/list?page=${page}&keyword=${keyword}`)
      else if (category) return axios.get(`${ENDPOINT}/company/list?page=${page}&category=${category}`)
      else return axios.get(`${ENDPOINT}/company/list?page=${page}`)
    },
    getCompanyInfoByID: (company_name) => axios.get(`${ENDPOINT}/company/detail/${company_name}`)
  },

  techstack: {
    getBasicInfo: (keyword, category) => {
      // console.log(keyword, typeof(keyword), category, typeof(category))
      if(keyword && category) return axios.get(`${ENDPOINT}/techstack`)
      else if (keyword) return axios.get(`${ENDPOINT}/techstack?keyword=${keyword}`)
      else if (category) return axios.get(`${ENDPOINT}/techstack?category=${category}`)
      else return axios.get(`${ENDPOINT}/techstack`)
    },
    getStackList: (page, keyword, category) => {
      if(keyword && category) return axios.get(`${ENDPOINT}/techstack/list?page=${page}`)
      else if (keyword) return axios.get(`${ENDPOINT}/techstack/list?page=${page}&keyword=${keyword}`)
      else if (category) return axios.get(`${ENDPOINT}/techstack/list?page=${page}&category=${category}`)
      else return axios.get(`${ENDPOINT}/techstack/list?page=${page}`)
    },
    getCategoryList: () => axios.get(`${ENDPOINT}/techstack/category-list`),
    getStackInfoByID: (techstackId) => axios.get(`${ENDPOINT}/techstack/detail/${techstackId}`),
    getAllTechstackList: () => axios.get(`${ENDPOINT}/techstack/all-techstack-list`)
  },

  statistics: {
    getBasicInfo: () => axios.get(`${ENDPOINT}/statistics`),
    getStatistics: (category) => axios.get(encodeURI(`${ENDPOINT}/statistics/detail/${category}`))
  },

  calendar: {
    getSchedule: (year, month) => axios.get(encodeURI(`${ENDPOINT}/calendar?year=${year}&month=${month}`)),
    getUpcomingSchedule: () => axios.get(`${ENDPOINT}/calendar/upcoming`)
  },
  
  article: {
    getBasicInfo: (keyword, tag, companyId) => {
      if (keyword && tag) return axios.get(`${ENDPOINT}/article`)
      else if (keyword) return axios.get(`${ENDPOINT}/article?keyword=${keyword}`)
      else if (tag) return axios.get(`${ENDPOINT}/article?tag=${tag}`)
      else if (companyId) return axios.get(`${ENDPOINT}/article?company_id=${companyId}`)
      else return axios.get(`${ENDPOINT}/article`)
      
    },
    viewcount: (articleId) => {
      if (process.env.REACT_APP_STAGE !== 'local') {
        return axios.post(`${ENDPOINT}/article/viewcount`, { article_id: articleId })
      }
    },
    getArticleList: (page, keyword, tag, companyId) => {
      if (keyword && tag) return axios.get(`${ENDPOINT}/article/list?page=${page}`)
      if (keyword) return axios.get(`${ENDPOINT}/article/list?page=${page}&keyword=${keyword}`)
      if (tag) return axios.get(`${ENDPOINT}/article/list?page=${page}&tag=${tag}`)
      if (companyId) return axios.get(`${ENDPOINT}/article/list?page=${page}&company_id=${companyId}`)
      else return axios.get(`${ENDPOINT}/article/list?page=${page}`)
    },
    getPopularArticle: () => axios.get(`${ENDPOINT}/article/popular_article`),
    getCategoryList: () => axios.get(`${ENDPOINT}/article/categorylist`),
    getRecommendedTagList : () => axios.get(`${ENDPOINT}/article/recommended_tags`),
    getFavoriteIDList: () => axios.get(`${ENDPOINT}/article/favorite/idlist`, authHeader()),
    getFavoriteItemList: (page) => axios.get(`${ENDPOINT}/article/favorite/itemlist?page=${page}`, authHeader()),
    addFavoriteItem: (data) => axios.post(`${ENDPOINT}/article/favorite/item`, data, authHeader()),
    deleteFavoriteItem: (data) => axios.delete(`${ENDPOINT}/article/favorite/item`, { ...authHeader(), data: data }),
    deleteAllFavoriteItem: () => axios.delete(`${ENDPOINT}/article/favorite/allitem`, authHeader())
  },

  recruit: {
    getJobPostList: () => axios.get(`${ENDPOINT}/recruit/list`),
    getJobPostByCompanyId: (companyId) => axios.get(`${ENDPOINT}/recruit/company/${companyId}`),
    getJobPostByTechstackId: (techstackId) => axios.get(`${ENDPOINT}/recruit/techstack/${techstackId}`),
    getJobPostByCompanyId: (companyId) => axios.get(`${ENDPOINT}/recruit/company/${companyId}`),
    getRecommendedCompanyJobPostByUser: (companyId) => axios.get(`${ENDPOINT}/recruit/recommendation/company?company_id=${companyId}`, authHeader()),
    getRecommendedTechstackJobPostByUser: (techstackId) => axios.get(`${ENDPOINT}/recruit/recommendation/techstack?techstack_id=${techstackId}`, authHeader()),
  },

  form: {
    getCategoryStackList: () => axios.get(`${ENDPOINT}/form/addcompany/category`),
    addCompanyRequest: (data) => axios.post(`${ENDPOINT}/form/addcompany/submit`, data),
    addScheduleRequest: (data) => axios.post(`${ENDPOINT}/form/addschedule/submit`, data),
    requestChange: (data) => axios.post(`${ENDPOINT}/form/editcompany/submit`, data),
    subscription: (data) => axios.post(`${ENDPOINT}/form/subscription`, data),
    requestVerificationCode: (email) => axios.post(`${ENDPOINT}/form/verification/request-code`, {email: email}),
    checkVerificationCode: (email, verificationCode) => {
      const data = { email: email, verificationCode: verificationCode }
      return axios.post(`${ENDPOINT}/form/verification/verify-code`, data)
    },
    sendCodenaryPositionInterest: (data) => axios.post(`${ENDPOINT}/form/codenary-position-interest`, data),
  },

  board: {
    getPostList: (page, keyword, tag, companyId) => {
      // if (keyword && tag) return axios.get(`${ENDPOINT}/article/list?page=${page}`)
      // if (keyword) return axios.get(`${ENDPOINT}/article/list?page=${page}&keyword=${keyword}`)
      // if (tag) return axios.get(`${ENDPOINT}/article/list?page=${page}&tag=${tag}`)
      // if (companyId) return axios.get(`${ENDPOINT}/article/list?page=${page}&company_id=${companyId}`)
      // else return axios.get(`${ENDPOINT}/article/list?page=${page}`)
    },
    getCategoryInfo: () => axios.get(`${ENDPOINT}/board/category`),
    getPost: (postId) => axios.get(`${ENDPOINT}/board/post/${postId}`, authHeader()),
    addPost: (data) => axios.post(`${ENDPOINT}/board/post`, data, authHeader()),
    editPost: (postId, data) => axios.put(`${AUTH_ENDPOINT}/board/post/${postId}`, data, authHeader()),
    deletePost: (data) => axios.delete(`${ENDPOINT}/board/post`, { ...authHeader(), data: data }),

    getCommentList: () => null,
    addComment: (commentId, data) => null,
    editComment: (commentId, data) => null,
    deleteComment: (commentId) => null
  },

  viewcount: {
    increaseTechblog: (contentId) => {
      if (process.env.REACT_APP_STAGE !== 'local') {
        return axios.post(`${ENDPOINT}/logs/view-content`, { type: 'TECHBLOG', content_id: contentId }, authHeader())
      }
    },
    increaseArchitecture: (contentId) => {
      if (process.env.REACT_APP_STAGE !== 'local') {
        return axios.post(`${ENDPOINT}/logs/view-content`, { type: 'ARCHITECTURE', content_id: contentId }, authHeader())
      }
    },
    increaseDiscussion: (contentId) => {
      if (process.env.REACT_APP_STAGE !== 'local') {
        return axios.post(`${ENDPOINT}/logs/view-content`, { type: 'DISCUSSION', content_id: contentId }, authHeader())
      }
    },
    increaseCompany: (contentId) => {
      if (process.env.REACT_APP_STAGE !== 'local') {
        return axios.post(`${ENDPOINT}/logs/view-content`, { type: 'COMPANY', content_id: contentId }, authHeader())
      }
    },
    increaseTechstack: (contentId) => {
      if (process.env.REACT_APP_STAGE !== 'local') {
        return axios.post(`${ENDPOINT}/logs/view-content`, { type: 'TECHSTACK', content_id: contentId }, authHeader())
      }
    },
    increaseJobpost: (contentId) => {
      if (process.env.REACT_APP_STAGE !== 'local') {
        return axios.post(`${ENDPOINT}/logs/view-content`, { type: 'JOPOST', content_id: contentId }, authHeader())
      }
    }
  },

  insight: {
    getInsightList: (last_key) => (
      last_key ? axios.get(`${ENDPOINT}/insight/list?last_key=${last_key}`)
               : axios.get(`${ENDPOINT}/insight/list`)
    ),
    getInsight: (id, signed_in) => (
      signed_in ? axios.get(`${ENDPOINT}/insight/detail/${id}`, authHeader())
                : axios.get(`${ENDPOINT}/insight/detail/${id}`)
    )
  },

  architecture: {
    getBasicInfo: () => axios.get(`${ENDPOINT}/architecture/basicinfo`),
    getArchitectureList: (page, category, techstackIds) => {
      if (category && techstackIds) return axios.get(`${ENDPOINT}/architecture/list?page=${page}`)
      if (category) return axios.get(`${ENDPOINT}/architecture/list?page=${page}&category=${category}`)
      if (techstackIds) return axios.get(`${ENDPOINT}/architecture/list?page=${page}&techstack_ids=${techstackIds}`)
      else return axios.get(`${ENDPOINT}/architecture/list?page=${page}`)
    },
    getArchitectureDetail: (id) => axios.get(`${ENDPOINT}/architecture/detail/${id}`),
    getPopularArchitecture: () => axios.get(`${ENDPOINT}/architecture/popular_article`),
    addComment: (architectureId, parentId, text) => {
      const data = {
        architecture_id: architectureId,
        parent_id: parentId,
        text: text
      }
      return axios.post(`${ENDPOINT}/architecture/comment/add`, data, authHeader())
    },
    editComment: (commentId, text) => {
      const data = { comment_id: commentId, text: text }
      return axios.post(`${ENDPOINT}/architecture/comment/edit`, data, authHeader())
    },
    deleteComment: (commentId) => {
      const data = { comment_id: commentId }
      return axios.post(`${ENDPOINT}/architecture/comment/delete`, data, authHeader())
    },
    getFavoriteArchitectureID: () => axios.get(`${ENDPOINT}/architecture/favorite/item/ids`, authHeader()),
    getFavoriteArchitectureItemList: () => axios.get(`${ENDPOINT}/architecture/favorite/item/list`, authHeader()),
    checkFavoriteArchitectureItem: (architectureId) => axios.get(`${ENDPOINT}/architecture/favorite/item/check?id=${architectureId}`, authHeader()),
    addFavoriteArchitectureItem: (architectureId) => {
      const data = { architecture_id: architectureId }
      return axios.post(`${ENDPOINT}/architecture/favorite/item/add`, data, authHeader())
    },
    deleteFavoriteArchitectureItem: (architectureId) => {
      const data = { architecture_id: architectureId }
      return axios.post(`${ENDPOINT}/architecture/favorite/item/delete`, data, authHeader())
    },

    deleteAllFavoriteArchitectureItem: () => axios.post(`${ENDPOINT}/architecture/favorite/item/deleteall`, {}, authHeader())
  },

  auth: {
    getBasicInfo: () => axios.get(`${AUTH_ENDPOINT}/basic-info`),
    loginUser: (data) => axios.post(`${AUTH_ENDPOINT}/login`, data),
    registerUser: (data, token) => axios.post(`${AUTH_ENDPOINT}/register-user`, data, authHeader(token)),
    getUser: () => axios.get(`${AUTH_ENDPOINT}/get-user`, authHeader()),
    checkUser: (username) => axios.get(`${AUTH_ENDPOINT}/check-user?username=${username}`),
    updateUser: (data) => axios.put(`${AUTH_ENDPOINT}/update-user`, data, authHeader()),
    updateUserNewsletter: (data) => axios.put(`${AUTH_ENDPOINT}/update-user-newsletter`, data, authHeader()),
    cancelUserNewsletter: () => axios.put(`${AUTH_ENDPOINT}/cancel-user-newsletter`, {}, authHeader()),
    deleteUser: () => axios.delete(`${AUTH_ENDPOINT}/delete-user`, authHeader()),
    uploadUserProfilePictureURL: `${AUTH_ENDPOINT}/upload-user-profile-img`,

    getMyResume: () => axios.get(`${AUTH_ENDPOINT}/myresume`, authHeader()),
    deleteMyResume: () => axios.delete(`${AUTH_ENDPOINT}/delete-myresume`, authHeader()),
    uploadMyResumeURL:() =>  `${AUTH_ENDPOINT}/upload-myresume`,
    updateSuggestionInfo: (data) => axios.post(`${AUTH_ENDPOINT}/update-suggestion-info`, data, authHeader()),
    getMyTechstack: () => axios.get(`${AUTH_ENDPOINT}/mytechstack`, authHeader()),
    addMyTechstack: (data) => axios.post(`${AUTH_ENDPOINT}/add-mytechstack`, data, authHeader()),
    removeMyTechstack: (techstackId) => {
      const data = { techstack_id: techstackId }
      return axios.post(`${AUTH_ENDPOINT}/remove-mytechstack`, data, authHeader())
    }
  },
  newsletter: {
    getNewsletter: (uid) => axios.get(`${ENDPOINT}/newsletter/list?uid=${uid}`),
    getManualContent: () => axios.get(`${ENDPOINT}/newsletter/manual-content/list`),
    requestNewsletter: (data) => axios.post(`${ENDPOINT}/newsletter/request`, data),
    cancelNewsletter: (uid) => axios.post(`${ENDPOINT}/newsletter/cancel`, { uid: uid })
  },
  discussion: {
    getTopicDetail: (discussionId) => axios.get(`${ENDPOINT}/discussions/${discussionId}`, authHeader()),
    getTopicList: (after) => {
      return after ? axios.get(`${ENDPOINT}/discussions?limit=100&after=${after}`)
                   : axios.get(`${ENDPOINT}/discussions?limit=100`)
    },
    getTopicTodayList: () => axios.get(`${ENDPOINT}/discussions/today`),
    getCommentList: (discussionId) => axios.get(`${ENDPOINT}/discussions/${discussionId}/comments/all`, authHeader()),
    voteOption: (discussionId, optionId) => {
      const body = { discussion_vote_option_id: optionId}
      return axios.post(`${ENDPOINT}/discussions/${discussionId}/votes`, body, authHeader())
    },
    addComment: (discussionId, parentId, text) => {
      const body = {
        parent_id: parentId,
        text: text
      }
      return axios.post(`${ENDPOINT}/discussions/${discussionId}/comments`, body, authHeader())
    },
    deleteComment: (discussionId, commentId) => axios.delete(`${ENDPOINT}/discussions/${discussionId}/comments/${commentId}`, authHeader()),
    editComment: (discussionId, commentId, text) => {
      const body = { text: text }
      return axios.put(`${ENDPOINT}/discussions/${discussionId}/comments/${commentId}`, body, authHeader())
    },
    updateCommentLikeCount: (discussionId, commentId, liked) => {
      const body = { enabled: liked }
      return axios.put(`${ENDPOINT}/discussions/${discussionId}/comments/${commentId}/likes`, body, authHeader())
    },
    getReportReasonTypes: () => axios.get(`${ENDPOINT}/discussions/comment_reports/reason_types`),
    submitReport: (commentId, reasonType, reason) => {
      const body = {
        reason_type: reasonType,
        reason_comment: reason
      }
      return axios.post(`${ENDPOINT}/discussions/comments/${commentId}/reports`, body, authHeader())
    },
    getMyCommentList: (pageNum) => axios.get(`${ENDPOINT}/discussions/comments/my-comments?page=${pageNum}`, authHeader()),
    getMyLikedCommentList: (pageNum) => axios.get(`${ENDPOINT}/discussions/comments/my-likes?page=${pageNum}`, authHeader()),
  },
  wanted: {
    // 원티드와의 연동 API
    getCompanyInfo: (wantedId) => axios.get(`${ENDPOINT}/wanted/company-info?wanted_id=${wantedId}`),
    getCompanyList: (keyword) => axios.get(`${ENDPOINT}/wanted/company-list?keyword=${keyword}`)
  }
}