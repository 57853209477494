import React, { useState } from 'react';
import { ENDPOINT } from '../../../config/dataService/endpoint';
import { Button as SemiButton, Input, Modal } from '@douyinfe/semi-ui';
import { getCookieItem, removeCookieItem, setCookieItem, COOKIE_ITEMS } from '../../../config/dataService/cookieControl';

const DevEndpointModal = () => {
    const [modalVisibile, setModalVisibile] = useState(false)
    const [endpointInput, setEndpointInput] = useState(getCookieItem(COOKIE_ITEMS.endpoint) || ENDPOINT)

    const handleOk = () => {
      setCookieItem(COOKIE_ITEMS.endpoint, endpointInput, 3600)
      setModalVisibile(false)
    }

    const handleReset = () => {
      removeCookieItem(COOKIE_ITEMS.endpoint)
      setModalVisibile(false)
    }

    const handleCancel = () => {
      setModalVisibile(false)
    }

    const renderFooter = () => (
      <>
        <SemiButton 
          type={"tertiary"} 
          theme={"light"} 
          size={"large"} 
          style={{borderRadius: "0.5rem"}}
          onClick={handleReset}
        >
          초기화
        </SemiButton>
        <SemiButton 
          type={"primary"} 
          theme={"light"} 
          size={"large"} 
          style={{borderRadius: "0.5rem"}}
          onClick={handleOk}
        >
          설정
        </SemiButton>
      </>
    )

    return ( 
      process.env.REACT_APP_STAGE != "production" &&
      <>
        <a 
          style={{
            marginRight: "20px", 
            textDecorationLine: "underline", 
            verticalAlign: "top",
            color: getCookieItem(COOKIE_ITEMS.endpoint) ? '#0064fa' : ''
          }}
          onClick={()=>{ setModalVisibile(true) }}
        >
          {getCookieItem(COOKIE_ITEMS.endpoint) ? '설정 적용 중' : '설정'}
        </a>
        <Modal
          centered={true}
          lazyRender={true}
          visible={modalVisibile}
          onCancel={handleCancel}
          title={"앤드포인트 설정"}
          footer={renderFooter()}
          style={{ maxWidth: "95%", padding: 24 }}
        >
          <Input
            showClear
            autofocus
            size={"large"}
            defaultValue={endpointInput}
            placeholder={"SERVER_ENDPOINT"}
            onChange={(value)=>setEndpointInput(value)}
          />
          <div style={{margin: "12px 6px", color: "#888888"}}>* 기본 만료 기간: 1시간</div>
        </Modal>
    </>
  );
}

export default DevEndpointModal