import React, { lazy, Suspense, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import withLayout from '../layout/withLayout';
 

const StatisticsPage = lazy(() => import('../container/pages/StatisticsPage'));
const TechblogPage = lazy(() => import('../container/pages/TechblogPage'));
const TechblogFavoritePage = lazy(() => import('../container/pages/TechblogFavoritePage'));
const CompanyDetailPage = lazy(() => import('../container/pages/CompanyDetailPage'));
const TechstackDetailPage = lazy(() => import('../container/pages/TechstackDetailPage'));
const CompanyPage = lazy(() => import('../container/pages/Company'));
const TechstackPage = lazy(() => import('../container/pages/Techstack'));
const InsightkPage = lazy(() => import('../container/pages/InsightPage'));
const InsightDetailPage = lazy(() => import('../container/pages/InsightDetailPage'));
const ArchitecturePage = lazy(() => import('../container/pages/ArchitecturePage'));
const AddCompanyPage = lazy(() => import('../container/pages/AddCompanyPage'));
const NotFound = lazy(() => import('../container/pages/404'));
const MainPage = lazy(() => import('../container/pages/MainPage'));
const LoginPage = lazy(() => import('../container/pages/LoginPage'));
const LoginCallbackPage = lazy(() => import('../container/pages/LoginCallbackPage'));
const RegisterPage = lazy(() => import('../container/pages/RegisterPage'));
const EditUserPage = lazy(() => import('../container/pages/EditUserPage'));
const MyActivityUserPage = lazy(() => import('../container/pages/MyActivityUserPage'));
const Calendar = lazy(() => import('../container/calendar/Calendar'));
const MyTechstackPage = lazy(() => import('../container/pages/MyTechstackPage'));
const NewsLetterPage = lazy(() => import('../container/pages/NewsLetterPage'));
const DiscussionDetailPage = lazy(() => import('../container/pages/DiscussionDetailPage'));
const DiscussionTodayDetailPage = lazy(() => import('../container/pages/DiscussionTodayDetailPage'));
const DiscussionPage = lazy(() => import('../container/pages/DiscussionPage'));
const UserProfilePage = lazy(() => import('../container/pages/UserProfilePage/UserProfilePage'));
const EditUserProfilePage = lazy(() => import('../container/pages/UserProfilePage/EditUserProfilePage'));
const GroupDetailPage = lazy(() => import('../container/pages/GroupProfilePage/GroupProfilePage'));
const CommingSoonPage = lazy(() => import('../container/pages/ComingSoon'));


const PageRouter = () => {
  // document.body.style.zoom = isDesktop ? "90%": "80%"

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 27 }}/>} style={{display: "block"}}/>
          </div>
        }
      >
        <Switch>
          <Route exact path={`/`} component={CommingSoonPage} />
          {/* <Route exact path={`/company/list`} component={CompanyPage} />
          <Route exact path={`/techstack/list`} component={TechstackPage} />
          <Route exact path={`/statistics`} component={StatisticsPage} />
          <Route exact path={`/techblog/list`} component={TechblogPage} />
          <Route exact path={`/architecture/list`} component={ArchitecturePage} />
          <Route exact path={`/techblog/favoritelist`} component={TechblogFavoritePage} />
          <Route exact path={`/company/detail/:id`} component={CompanyDetailPage} />
          <Route exact path={`/techstack/detail/:id`} component={TechstackDetailPage} />
          <Route exact path={`/company/addcompany`} component={AddCompanyPage} />
          <Route exact path={`/my-techstack`} component={MyTechstackPage} />
          <Route exact path={`/insight/list`} component={InsightkPage} />
          <Route exact path={`/insight/detail/:id`} component={InsightDetailPage} />
          <Route exact path={`/login`} component={LoginPage} />
          <Route exact path={`/login/:type`} component={LoginCallbackPage} />
          <Route exact path={`/register`} component={RegisterPage} />
          <Route exact path={`/discussion/today`} component={DiscussionTodayDetailPage} />
          <Route exact path={`/discussion/detail/:id`} component={DiscussionDetailPage} />
          <Route exact path={`/discussion/list`} component={DiscussionPage} />
          <Route exact path={`/newsletter`} component={NewsLetterPage} />
          <Route exact path={`/user-profile/:id`} component={UserProfilePage} />
          <Route exact path={`/user-profile/edit`} component={EditUserProfilePage} />
          <Route exact path={`/group/detail/:id`} component={GroupDetailPage} />
          <Route exact path={`/comming-soon`} component={CommingSoonPage} />
          <Route path={`/my-activity`} component={MyActivityUserPage} />
          <Route path={`/edituser`} component={EditUserPage} />
          <Route path={`/calendar`} component={Calendar} /> */}
          <Route path={`*`} component={NotFound} />
        </Switch>
      </Suspense>
    </Switch>
  );
};

export default withLayout(PageRouter);
