import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import PageRouter from './routes/index';
import './static/css/style.scss';
import config from './config/theme/config';
import { RecoilRoot } from 'recoil';
import { getLocalStorageItem, LOCALSTORAGE_ITEMS, removeLocalStorageItem } from './config/dataService/localStorageControl';
import { signin_global } from './recoilstate';
import { useRecoilState } from 'recoil';
import jwt_decode from 'jwt-decode'
import mixpanel from 'mixpanel-browser'
import { LocaleProvider } from '@douyinfe/semi-ui';
import ko_KR from '@douyinfe/semi-ui/lib/es/locale/source/ko_KR';
import { mixpanelTrack } from './config/utils';
import 'moment/locale/ko'
import moment from 'moment'
moment.locale('ko')

import AOS from "aos";
import "aos/dist/aos.css";

const { theme } = config;

const ProviderConfig = () => {
  const [path, setPath] = useState(window.location.pathname);
  const [signin, setSignin] = useRecoilState(signin_global)

  const initializeUserInfo = () => {
    const userinfo = getLocalStorageItem(LOCALSTORAGE_ITEMS.userinfo)
    if (userinfo) {
      let decoded_jwt = jwt_decode(userinfo.access_token)
      if (decoded_jwt.exp > + new Date()/1000) {
        setSignin(true)
      } else {
        removeLocalStorageItem(LOCALSTORAGE_ITEMS.userinfo)
      }
    }
  }

  useEffect(() => {
    let unmounted = false;
    AOS.init({ once: true })
    initializeUserInfo()
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);


  return (
    <ConfigProvider direction={'ltr'}>
      <ThemeProvider theme={{ ...theme }}>
        <LocaleProvider locale={ko_KR}>
          <Router>
            <Route path="/" component={PageRouter} />
          </Router>
        </LocaleProvider> 
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  mixpanel.init('cb027f6c069d4ead16dd6bc3a196a638', { debug: true });
  return (
    <RecoilRoot>
      <Provider store={store}>
        <ProviderConfig />
      </Provider>
    </RecoilRoot>
  );
}

export default hot(App);
