import mixpanel from 'mixpanel-browser'

const CalendarTypeToKorean = (type) => {
    let koreanWord = ''
    switch (type) {
      case 'webinar':
        koreanWord = '웨비나'
        break;
      case 'conference':
        koreanWord = '컨퍼런스'
        break;
      case 'competition':
        koreanWord = '경진대회'
        break;
      case 'others':
        koreanWord = '기타'
        break;
      default:
        return ''
    }
  
    return koreanWord
}


const TechstackTypeToEng = (type) => {
  let convertedWord = ''
  switch (type) {
    case '백엔드':
      convertedWord = 'backend'
      break;
    case '프론트엔드':
      convertedWord = 'frontend'
      break;
    case '데이터':
      convertedWord = 'data'
      break;
    case '데이터베이스':
      convertedWord = 'database'
      break;
    case '모바일':
      convertedWord = 'mobile'
      break;
    case '테스팅툴':
      convertedWord = 'testing'
      break;
    case '데브옵스':
      convertedWord = 'devops'
      break;
    case '협업툴':
      convertedWord = 'collaboration'
      break;
    case '언어':
      convertedWord = 'language'
      break;
    case '기타':
      convertedWord = 'others'
      break;
    default:
      return ''
  }
  return convertedWord
}

const CalendarTypeList = [
  { key: 'webinar', value: '웨비나' },
  { key: 'conference', value: '컨퍼런스' },
  { key: 'competition', value: '경진대회' },
  { key: 'others', value: '기타' },
]

const TechstackLevelDescription = {
  3: "경험은 없지만, 해당 기술 스택에 대해 이해가 가능한 수준",
  4: "어느 정도 경험이 있으며, 어렵지 않게 기술 스택을 사용할 수 있는 수준",
  5: "풍부한 경험이 있고 많이 다뤄봤으며, 매우 능숙하게 사용이 가능한 수준"
}

const mixpanelTrack = (obj, action) => {
  if(process.env.REACT_APP_STAGE === 'production') {
    mixpanel.track(`${obj} ${action}`)
  }
}

const mixpanelRegisterEmail = (userInfo) => {
  mixpanel.alias(userInfo.email)
  mixpanel.register({
    "$name": userInfo.username,
    "$email": userInfo.email,
    "$company": userInfo.company,
    "$experience": userInfo.experience,
    "$job": userInfo.job
  })
}

const mixpanelIdentifyEmail = (userInfo) => {
  mixpanel.identify(userInfo.email)
  mixpanel.people.set({
    "$name": userInfo.username,
    "$email": userInfo.email,
    "$company": userInfo.company,
    "$experience": userInfo.experience,
    "$job": userInfo.job
  })
}

const timeForToday = (value) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
  if (betweenTime < 5) return '방금 전';
  if (betweenTime < 60) {
      return `${betweenTime}분 전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간 전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 7) {
      return `${betweenTimeDay}일 전`;
  }

  return `${new Date(value).toLocaleDateString()}`;
}

const getDayInKorean = (dateObject) => {
  var weekList = ['일', '월', '화', '수', '목', '금', '토'];
  let month = dateObject.getMonth() + 1
  let day = dateObject.getDate()
  return `${month}. ${day} (${weekList[dateObject.getDay()]})`;
}


const addRefParam = (link) => {
  let url = new URL(link)
  let params = new URLSearchParams(url.search)
  params.append('ref', 'codenary')
  return `${url.origin}${url.pathname}?${params.toString()}`
}

const validateUsername = (username) => {
  if (!username) return false

  const re = new RegExp('^[a-zA-Zㄱ-힣0-9]*$');
  return username.length < 12 && re.test(username)
}

const snsName = {
  facebook: 'Facebook',
  github: 'Github',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube'
}


export { 
  CalendarTypeToKorean,
  TechstackTypeToEng,
  CalendarTypeList,
  TechstackLevelDescription,
  mixpanelTrack,
  mixpanelRegisterEmail,
  mixpanelIdentifyEmail,
  timeForToday,
  getDayInKorean,
  addRefParam,
  validateUsername,
  snsName
}