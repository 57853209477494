import { atom } from 'recoil';
import { getLocalStorageItem, LOCALSTORAGE_ITEMS } from './config/dataService/localStorageControl';

let signin_global = atom({
  key: 'signin',
  default: false, 
});

let userinfo_global = atom({
  key: 'userinfo',
  default: getLocalStorageItem(LOCALSTORAGE_ITEMS.userinfo) || {
    access_token: '',
    id: '',
    login_type: '',
    email: '',
    username: '',
    imgURL: '',
    job: '',
    company: ''
  }
});

export { signin_global, userinfo_global } ;