import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if(process.env.REACT_APP_STAGE === 'production') {
    Sentry.init({
        dsn: "https://7245f5d7a53345fba622c95b449db537@o1159555.ingest.sentry.io/6243815",
        integrations: [new BrowserTracing()],
        maxBreadcrumbs: 50,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(<App/>, document.getElementById('root'));
